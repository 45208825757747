@import '../theme-bootstrap';

body {
  &.ajax-wait,
  &.ajax-wait * {
    cursor: progress !important;
  }
}

.product-store-check {
  // Shared styles for all devices.
  .location-controls {
    text-align: left;
    position: relative;
    margin: 0 0 4px;
    &__hdr {
      @include font--subheading;
      display: inline-block;
      margin: 0 12px 0 0;
      text-transform: uppercase;
      font-size: 22px;
      letter-spacing: 0.05em;
      @include breakpoint($medium-up) {
        display: block;
      }
      @include breakpoint($large-up) {
        display: inline-block;
      }
    }
    &__input {
      @include font--subheading;
      padding: 0.69em;
      line-height: -10px;
      vertical-align: top;
      color: $color-black;
      width: 75%;
      border-radius: 0;
      @include breakpoint($medium-up) {
        width: 35%;
      }
    }
    &__info {
      @include a1;
      display: inline;
      text-transform: none;
      margin-right: 16px;
      @include breakpoint(($medium-up) (orientation portrait)) {
        margin-right: 2px;
      }
    }
    &__link {
      display: none;
      @include a1;
      &--edit {
        display: inline;
        margin-right: 24px;
        @include breakpoint(($medium-up) (orientation portrait)) {
          margin-right: 10px;
        }
      }
    }
    &__zip {
      margin-top: 10px;
    }
    &__distance {
      display: inline-block;
      position: absolute;
      top: -2px;
      @include breakpoint($medium-up) {
        top: 12px;
      }
      @include breakpoint($large-up) {
        top: -2px;
      }
      &--select {
        height: 30px;
        @include breakpoint($medium-up) {
          padding-right: 30px;
          line-height: 16px;
        }
        @include breakpoint($large-up) {
          padding-right: 40px;
          line-height: auto;
        }
        // selectbox overrides for the distance dropdown
        .selectBox-label {
          line-height: 25px;
        }
        // selectBox overrides for the menu placed at the bottom of the DOM
        &-selectBox-dropdown-menu {
          li {
            a {
              padding: 6px 10px 6px 15px;
            }
            &:last-child {
              a {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    &__close {
      position: absolute;
      right: 0;
      top: 5px;
    }
    &__loading {
      text-align: center;
      margin-top: 10px;
    }
  }

  // Door specific styles for all devices
  .doors-container {
    .container-row {
      &__doors {
        &--msg {
          margin: 0.5em 0;
        }
        &.doors-avail {
          .container-row__doors--msg {
            display: none;
          }
        }
        .door-row {
          margin-bottom: 0.5em;
          .door-info {
            &__address {
              @include t1;
              width: 100%;
              float: left;
              font-size: 14px;
              text-transform: capitalize;
              @include breakpoint($medium-up) {
                width: 75%;
              }
              &--name {
                @include t2;
                text-transform: uppercase;
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            &__avail {
              width: 100%;
              float: left;
              padding-left: 0;
              @include breakpoint($medium-up) {
                width: 25%;
                padding-left: 0.5em;
              }
              .avail-info {
                &__msg {
                  @include breakpoint($medium-up) {
                    padding: 0.5em 0 0;
                  }
                  &--span {
                    border-radius: 50%;
                    display: inline-block;
                    height: 0.75em;
                    width: 0.75em;
                    margin: 0.15em 0.25em 0 0;
                    padding: 0.2em;
                    vertical-align: top;
                    vertical-align: inherit;
                  }
                  &--text {
                    @include font--subheading;
                    font-size: 16px;
                  }
                  .msg-span {
                    &__avail {
                      background-color: $color-green;
                    }
                    &__not-avail {
                      background-color: $color-red;
                    }
                    &__call {
                      background-color: $color-orange;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // Error styles for all devices
  .inline-errors {
    @include font--subheading;
    font-size: 18px;
    text-transform: uppercase;
    text-align: left;
    clear: both;
    &__error {
      display: none;
      margin: 0;
      padding: 0.5em 0 0;
      @include breakpoint($medium-up) {
        margin: 0.5em 0 0;
        padding: 0;
      }
      &.active {
        display: block;
      }
    }
  }

  // Inline specific styles
  &__inline {
    @include breakpoint(($medium-only) (orientation portrait)) {
      text-align: right;
    }
    .inline-container {
      text-align: left;
      border-bottom: 1px solid $color-light-gray;
      @include breakpoint(($medium-only) (orientation portrait)) {
        width: 50%;
        display: inline-block;
      }
      &__doors {
        .doors-container {
          max-height: 10em;
          overflow-y: auto;
        }
      }
      &__link {
        display: none;
        @include a1;
        margin-bottom: 1em;
      }
    }
  }

  // Overlay specific styles
  &__overlay {
    margin: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $color-white;
    z-index: 1000;
    overflow-y: scroll;
    .overlay-container {
      padding: 6%;
      .location-controls {
        text-align: center;
        border-bottom: 1px solid $color-light-gray;
        padding: 0 0 0.5em;
        &__hdr {
          text-align: left;
          width: 100%;
          border-bottom: 1px solid $color-light-gray;
          padding: 0 0 0.5em;
          font-size: 24px;
        }
        &__input {
          &--zip {
            width: 70%;
            border-radius: 0;
            background: 0;
            float: left;
          }
        }
        &__btn {
          &--zip {
            float: right;
          }
        }
        &__zip {
          margin: 0.5em 0 0.75em;
        }
        &__info {
          margin: 0;
          float: left;
          top: 10px;
          position: relative;
        }
        &__distance {
          position: relative;
          float: right;
          width: 4.25em;
          &--select {
            height: 30px;
            line-height: 26px;
            padding-right: 20px;
            text-transform: lowercase;
          }
        }
        &__close {
          position: absolute;
          right: 0;
          top: 2px;
        }
      }
      .doors-container {
        .container-row {
          .doors-avail {
            margin-top: 0.75em;
          }
          .door-row {
            margin-bottom: 0.85em;
            padding-bottom: 0.95em;
            border-bottom: 1px solid $color-light-gray;
            .door-info {
              &__address {
                width: 100%;
              }
              &__avail {
                width: 100%;
                float: left;
                padding-left: 0;
                .avail-info__msg {
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  // State styles for all devices
  .psc-state-init {
    .inline-container {
      &__link {
        display: inline-block;
      }
    }
  }
  .psc-state-error {
    .overlay-container {
      .location-controls {
        &__info {
          display: none;
        }
      }
      .container-row {
        &__doors {
          display: none;
        }
      }
    }
  }
}
